import {
  VStack,
  HStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  AccordionButton,
  Box,
  Icon,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FaCheckCircle } from 'react-icons/fa';
import { IoIosCloseCircleOutline } from 'react-icons/io';

function CardPowertrain() {
  const { t } = useTranslation('global');
  return (
    <VStack
      align="flex-start"
      spacing={0}
      borderWidth="1px"
      borderColor="blackAlpha.500"
      p={4}
      borderRadius={'lg'}
    >
      <Text mb={4} fontSize="lg" fontWeight="extrabold">
        Powertrain
      </Text>
      <Text>$0 Deductible</Text>
      <Text>$1,500 Maximum per claim</Text>
      <Text>Maximum of all claims up to vehicle value</Text>
      <Text>Pay in up to 12 equal payments</Text>
      <Text>Extend your 90 days up to 1 year</Text>
      <br />
      {/* <Text fontWeight="bold">
                Includes 24/7 Roadside Assistance
                </Text> */}

      <Accordion allowToggle w="full" mt={2}>
        <AccordionItem>
          <AccordionButton px={0} w="100%">
            <Box as="span" flex="1" textAlign="left">
              <Text color="primary.500" fontSize={'sm'} fontWeight="bold">
                {/* Click here for coverage details */}
                What's covered?
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4} mx={0} px={0} fontSize="xs">
            <HStack>
              <Icon as={FaCheckCircle} w={'14px'}
                h={'14px'} color="purple" />
              <Text>{t('sharedCoverageFeature4')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'}
                h={'14px'} color="purple" />
              <Text>{t('sharedCoverageFeature5')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'}
                h={'14px'} color="purple" />
              <Text>{t('sharedCoverageFeature6')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'}
                h={'14px'} color="purple" />
              <Text>{t('sharedCoverageFeature7')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'}
                h={'14px'} color="purple" />
              <Text>{t('sharedCoverageFeature8')}</Text>
            </HStack>

            <HStack>
              <Icon
                as={FaCheckCircle}
                w={'14px'}
                h={'14px'}
                color="purple"
              />
              <Text>{t('sharedCoverageFeature9')}</Text>
            </HStack>

            <HStack>
              <Icon as={IoIosCloseCircleOutline} w={'16px'} h={'16px'} color="red" />
              <Text as='i'>{t('comprehensiveOnlyFeature2')}</Text>
            </HStack>

            <HStack>
              <Icon as={IoIosCloseCircleOutline} w={'16px'} h={'16px'} color="red" />
              <Text as='i'>{t('comprehensiveOnlyFeature3')}</Text>
            </HStack>

            <HStack>
              <Icon as={IoIosCloseCircleOutline} w={'16px'} h={'16px'} color="red" />
              <Text as='i'>{t('comprehensiveOnlyFeature5')}</Text>
            </HStack>

            <HStack>
              <Icon as={IoIosCloseCircleOutline} w={'16px'} h={'16px'} color="red" />
              <Text as='i'>{t('comprehensiveOnlyFeature6')}</Text>
            </HStack>

            <HStack>
              <Icon as={IoIosCloseCircleOutline} w={'16px'} h={'16px'} color="red" />
              <Text as='i'>{t('comprehensiveOnlyFeature7')}</Text>
            </HStack>

            <HStack>
              <Icon as={IoIosCloseCircleOutline} w={'16px'} h={'16px'} color="red" />
              <Text as='i'>{t('comprehensiveOnlyFeature8')}</Text>
            </HStack>

            <HStack>
              <Icon as={IoIosCloseCircleOutline} w={'16px'} h={'16px'} color="red" />
              <Text as='i'>{t('comprehensiveOnlyFeature9')}</Text>
            </HStack>

            <HStack>
              <Icon as={IoIosCloseCircleOutline} w={'16px'} h={'16px'} color="red" />
              <Text as='i'>{t('comprehensiveOnlyFeature10')}</Text>
            </HStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Text my={2} fontWeight="extrabold" fontSize="lg">
        {/* $575 on a 1 Year contract */}
        $575 - 1 year coverage
      </Text>
    </VStack>
  );
}

export default CardPowertrain;
