import {
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
// import { useTranslation } from 'react-i18next';

import CardPowertrain from './WarrantyCardPowertrain';
import CardComprehensive from './WarrantyCardComprehensive';

function WarrantyCard() {
  // const { i18n, t } = useTranslation('global');
  return (
    <>
     <SimpleGrid w="full" columns={[1, null, 2]} spacing={4}>
            <CardPowertrain />
            <CardComprehensive />
          </SimpleGrid>
          <Text fontSize="xs" fontStyle="italic" my={4}>
        * Vehicle must be less than 12 years old and no more than 200,000 kms to
        be eligible
      </Text>
    </>
  );
}

export default WarrantyCard;
