const getIPDetails = async () => {

    try {
        const ipRaw = await fetch('https://api.ipify.org', {
            method: 'GET',
            headers: {},
        });
        const IP = await ipRaw.text();
        const data = await fetch(
            `https://api.ipstack.com/${IP}?access_key=f5fc00b7fc41d4b7c5b4c91afb20f262&format=1`,
            {
                method: 'GET',
                headers: {},
            }
        )
            .then((res) => {
                return res.json();
            });
        return data;
    }
    catch (error) {
        console.log(JSON.stringify(error))
        return null;
    }

};

export default getIPDetails;


// let response = {
//     "ip": "65.92.241.226",
//     "type": "ipv4",
//     "continent_code": "NA",
//     "continent_name": "North America",
//     "country_code": "CA",
//     "country_name": "Canada",
//     "region_code": "QC",
//     "region_name": "Quebec",
//     "city": "Mont-Royal",
//     "zip": "H4J 2C2",
//     "latitude": 45.52973937988281,
//     "longitude": -73.70665740966797,
//     "location": {
//         "geoname_id": 6077315,
//         "capital": "Ottawa",
//         "languages": [
//             {
//                 "code": "en",
//                 "name": "English",
//                 "native": "English"
//             },
//             {
//                 "code": "fr",
//                 "name": "French",
//                 "native": "Français"
//             }
//         ],
//         "country_flag": "https://assets.ipstack.com/flags/ca.svg",
//         "country_flag_emoji": "🇨🇦",
//         "country_flag_emoji_unicode": "U+1F1E8 U+1F1E6",
//         "calling_code": "1",
//         "is_eu": false
//     },
//     "time_zone": {
//         "id": "America/Toronto",
//         "current_time": "2023-01-30T07:27:35-05:00",
//         "gmt_offset": -18000,
//         "code": "EST",
//         "is_daylight_saving": false
//     },
//     "currency": {
//         "code": "CAD",
//         "name": "Canadian Dollar",
//         "plural": "Canadian dollars",
//         "symbol": "CA$",
//         "symbol_native": "$"
//     },
//     "connection": {
//         "asn": 577,
//         "isp": "Bell Canada"
//     }
// }