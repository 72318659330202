import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

export const isDev = true;

let isDevLocalServer = false; // for true must enable emulator

export const enableMultiLanguage = false;
export const masterSafetyPromotionEnabled = true; // enables this option in /start landing page
export const newVerifyFlow = true; // if false, use old verify, if true, new verify flow with top top numbers
export const kountInsteadOfBanking = false; // in verify flow, step 3 is kount or banking

export const showUserBadge = false; // if true, after verify, redirect to badge, also shown on id card

export const allowManualVehicle = true; // pave only or manual vin entry allowed
export const deployed = isDev ? '250113 0900' : '';

if (!isDev) {
  // make sure prod cannot use localserver check
  isDevLocalServer = false;
}
// export const logrocket_id = isDev ? 'bjvkoo/eeze_customer_app_dev' : 'bjvkoo/eeze_customer_app_prod';

let firebaseConfig;
let baseServerUrl;

if (isDev) {
  // dev credentials
  baseServerUrl = process.env.REACT_APP_BASE_SERVER_DEV;
  firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY_DEV,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN_DEV,
    projectId: process.env.REACT_APP_PROJECT_ID_DEV,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET_DEV,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
  };

  if (process.env.REACT_APP_APP_ID_DEV) {
    firebaseConfig.appId = process.env.REACT_APP_APP_ID_DEV;
  };

  if (process.env.REACT_APP_MEASUREMENT_ID_DEV) {
    firebaseConfig.measurementId = process.env.REACT_APP_MEASUREMENT_ID_DEV;
  };
} else {
  // prod credentials
  baseServerUrl = process.env.REACT_APP_BASE_SERVER;
  firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  };

  if (process.env.REACT_APP_APP_ID) {
    firebaseConfig.appId = process.env.REACT_APP_APP_ID;
  };

  if (process.env.REACT_APP_MEASUREMENT_ID) {
    firebaseConfig.measurementId = process.env.REACT_APP_MEASUREMENT_ID;
  };
};

// local 
if (isDev && isDevLocalServer) {
  baseServerUrl = 'http://127.0.0.1:5001/eeze-dev-storage-db-auth/us-central1';
}
// Initialize Firebase
initializeApp(firebaseConfig);
export const db = getFirestore();

// recaptcha site key
export const recaptchaKey = '6LfIQn8pAAAAALZyye5yruoLXD8ZD2nQzc5hUPsa';

export const EEZE_LOGIN_URL = isDev ? 'https://dev-app.eeze.co/login' : 'https://app.eeze.co/login';

export const CHECK_CELL = baseServerUrl + '/pub/checkCell';

export const SEND_EMAIL = baseServerUrl + '/sendEmail?token=ezeze&';

export const SEND_EMAIL_TO_SELLER = baseServerUrl + '/app/sendEmailToSeller';

export const SEND_EMAIL_TO_ADMIN = baseServerUrl + '/app/sendEmailToAdmin';

export const STAGE_TWO_VERIFY = baseServerUrl + '/app/stageTwoVerifyUser';

export const GET_KBA = baseServerUrl + '/app/getKBA';

export const SUBMIT_KBA = baseServerUrl + '/app/submitKBA';

export const SEND_EMAIL_LINK = baseServerUrl + '/app/sendEmailLink';

export const SEND_SMS = baseServerUrl + '/sendSMSMessage?token=ezeze&';

export const SEND_SMS_SIGNUP_LINK = baseServerUrl + '/pub/sendSMSSignupLink?token=ezeze&';

export const SEND_SMS_LINK = baseServerUrl + '/app/sendSMSLink';

export const CARFAX_REQUIRED = baseServerUrl + '/app/carfaxRequired';

export const INSPECTION_REQUIRED = baseServerUrl + '/app/inspectionRequired';

export const CAPTURE_VEHICLE = baseServerUrl + '/app/createSession';

export const TWILIO_START_VERIFY = 'https://twilio-verify-2977-dev.twil.io/start-verify';

export const TWILIO_VERIFY_OTP = 'https://twilio-verify-2977-dev.twil.io/verify-otp';

export const GENERATE_LICENSE_VERIFY = baseServerUrl + '/app/generateInquiry';

export const DELETE_SHORT_URL = baseServerUrl + '/app/deleteShortUrl';

export const FUND_WALLET = baseServerUrl + '/app/fundWallet';

export const PURCHASE_ADDON = baseServerUrl + '/app/purchaseAddon';

export const BANKING_IFRAME = isDev ?
  'https://connector-sandbox.aggregation.zumrails.com/?customerid=25fec733-27e0-46ef-8f6e-51b3adab0ed9&testinstitution=true&buttonbgcolor=%25236d2367&logourl=https%253A%252F%252Feeze-website.web.app%252Fimg%252Fshield1024.png&tecurl=https%253A%252F%252Feeze.co%252Fterms-of-use%252F&gettransactions=false'
  : 'https://connector.aggregation.zumrails.com/?customerid=8621f191-a0a4-47bf-ab25-2ffb5a7342a7&testinstitution=false&buttonbgcolor=%25236d2367&logourl=https%253A%252F%252Feeze-website.web.app%252Fimg%252Fshield1024.png&adduserinportal=true';

export const GET_BANKING_IFRAME = baseServerUrl + '/app/getIframeUrl';

export const SEND_EMAIL_PASSCODE = baseServerUrl + '/app/sendEmailPasscode';

export const NOTIFY_SAFETY_SELECTED = baseServerUrl + '/app/notifySafetySignup';

export const DECODE_VIN = baseServerUrl + '/app/decodeVIN';

export const GENERATE_CONTRACT = baseServerUrl + '/app/generateFinalContract';

export const VERIFY_CAPTCHA = baseServerUrl + '/verifyCaptcha';

export const allProvinces = [
  {
    "Ontario": "on",
  },
  {
    "Quebec": "qc",
  },
  {
    "Manitoba": "mb",
  },
  {
    "Saskatchewan": "sk",
  },
  {
    "Alberta": "ab",
  },
  {
    "British Columbia": "bc",
  },
  // {
  //   "Nova Scotia": "ns",
  // },
  // {
  //   "Prince Edward Island": "pe",
  // },
  // {
  //   "New Brunswick": "nb",
  // },
  // }, {
  //   "Northwest Territories": "nt",
  // }, {
  //   "Labrador": "nl",
  // }, {
  //   "Newfoundland": "nf",
  // }, {
  //   "Nunavut": "nu",
  // {
  //   "Québec": "qc",
  // }, 
  // }, {
  //   "Yukon": "yt",
]

export const eligibleWarrantyProvinces = ['on', 'qc'];

// need to put this on the server
export const LISTING = {
  STATUS: {
    PRIVATE: 'PRIVATE', // for vehicles only no listing
    DRAFT: 'DRAFT',
    POSTED: 'POSTED',
    ACCEPTED: 'ACCEPTED',  // confirm final price and odometer
    SELECT_OPTIONS: 'SELECT_OPTIONS', // select plan
    DISPLAY_OPTIONS: 'DISPLAY_OPTIONS', // view pics and carfax
    BUYER_FUNDING: 'BUYER_FUNDING',
    BUYER_SIGNING: 'BUYER_SIGNING',
    BUYER_SIGNED: 'BUYER_SIGNED',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    ERROR: 'ERROR',
  },
  TYPE: {
    VEHICLE: 'VEHICLE',
    PRODUCT: 'PRODUCT'
  },
};

export const DEFAULT_PLAN_INDEX = '1';

export const PLANS = [
  {
    name: 'Free',
    nameFr: 'Gratuit',
    active: true,
    planIndex: '0',
    skipToBuyerSigning: true, // for free plan, no display or wallet needed
    features: [
      0, 3, 4,
    ],
    includedWarrantyCode: '',
    carfaxVHRIncluded: false,
    digitalInspectionIncluded: false,
    price: {
      on: {
        priceIncludingTax: 0,
        priceBeforeTax: 0,
        taxes: {
          gst: 0,
          qst: 0,
        },
      },
      qc: {
        priceIncludingTax: 0,
        priceBeforeTax: 0,
        taxes: {
          gst: 0,
          qst: 0,
        },
      },
    },
    cancellation: {
      on: {
        priceIncludingTax: 0,
        priceBeforeTax: 0,
        taxes: {
          gst: 0,
          qst: 0,
        },
      },
      qc: {
        priceIncludingTax: 0,
        priceBeforeTax: 0,
        taxes: {
          gst: 0,
          qst: 0,
        },
      },
    },
  },
  {
    name: 'Trust',
    nameFr: 'Trust',
    active: true,
    planIndex: '1',
    features: [
      0, 3, 4, 5, 6, 7, 9
    ],
    includedWarrantyCode: 'BASE90',
    carfaxVHRIncluded: false,
    digitalInspectionIncluded: true,
    price: {
      on: {
        priceBeforeTax: 95,
        priceIncludingTax: 107.35,
        taxes: {
          gst: 12.35,
          qst: 0,
        },
      },
      qc: {
        priceBeforeTax: 95,
        priceIncludingTax: 109.23,
        taxes: {
          gst: 4.75,
          qst: 9.48,
        },
      },
    },
    cancellation: {
      on: {
        priceIncludingTax: 22.6,
        priceBeforeTax: 20,
        taxes: {
          gst: 0,
          qst: 2.6,
        },
      },
      qc: {
        priceIncludingTax: 23,
        priceBeforeTax: 20,
        taxes: {
          gst: 1,
          qst: 2,
        },
      },
    },
  },
  {
    name: 'Protect',
    nameFr: 'Protect',
    planIndex: '2',
    active: true,
    features: [
      0, 3, 4, 5, 6, 7, 9, 10, 11
    ],
    includedWarrantyCode: 'BASE90',
    carfaxVHRIncluded: true,
    digitalInspectionIncluded: true,
    price: {
      on: {
        priceBeforeTax: 155,
        priceIncludingTax: 175.15,
        taxes: {
          gst: 20.15,
          qst: 0,
        },
      },
      qc: {
        priceBeforeTax: 155,
        priceIncludingTax: 178.21,
        taxes: {
          gst: 7.75,
          qst: 15.46,
        },
      },
    },
    cancellation: {
      on: {
        priceIncludingTax: 67.8,
        priceBeforeTax: 60,
        taxes: {
          gst: 7.8,
          qst: 0,
        },
      },
      qc: {
        priceIncludingTax: 68.99,
        priceBeforeTax: 60,
        taxes: {
          gst: 3,
          qst: 5.99,
        },
      },
    },
  },
];

export const FEATURES = {
  0: {
    name: 'User Verification',
    nameFr: "Vérification de l'usager",
  },
  1: {
    name: 'Create and share listings',
    nameFr: "Création et partage d'annonces",
  },
  2: {
    name: 'Offer Management',
    nameFr: "Gestion des offres",
  },
  3: {
    name: 'In App Digital Sale Contract',
    nameFr: "Contrat de vente numérique",
  },
  4: {
    name: 'Vehicle Insurance Quote',
    nameFr: "Soumission assurance auto",
  },
  5: {
    name: 'Escrow Service (payment released only once both parties agree)',
    nameFr: "Service d’entiercement (paiement envoyé seulement lorsque les deux parties sont d’accord)",
  },
  6: {
    name: 'Real-time Payment',
    nameFr: "Paiement en temps réel",
  },
  7: {
    name: 'Upgraded Digital Inspection Report',
    nameFr: "Rapport d’inspection numérique avancé",
  },
  8: {
    name: '3 months of sirius XM',
    nameFr: "Trois mois de Sirius XM",
  },
  9: {
    name: '90 Day Powetrain Warranty and $95 Upgrade Credit',
    nameFr: "Garantie de groupe motopropulseur de 90 jours et crédit de mise à niveau de 95 $",
  },
  10: {
    name: 'Carfax Vehicle History Report',
    nameFr: "Rapport d'historique du véhicule Carfax",
  },
  11: {
    name: 'Carfax Lien Search Report',
    nameFr: "Rapport Carfax sur les charges financières liées au véhicule",
  },
  12: {
    name: 'Purchase financing options',
    nameFr: "Options de financement à l'achat",
  },
  13: {
    name: 'Comprehensive Warranty Options up to 3 years available',
    nameFr: "Options de garantie complète jusqu'à 3 ans disponibles",
  },
  14: {
    name: '2-year warranty',
    nameFr: "Garantie de 2 ans",
  },
  15: {
    name: '3-year warranty',
    nameFr: "Garantie de 3 ans",
  },
};

// upgrades are $95 less than buy plans
export const LINK = isDev ? {
  privacyUrl: 'https://eeze.co/privacy-policy/',
  termsUrl: 'https://eeze.co/terms-of-use/',
  amlUrl: 'https://eeze.co/aml-program/',
  googleReview: 'https://g.page/r/CY3GFDptbIQMEBI/review',
  contactUrl: 'https://eeze.co/contact-us/',
  buyCarOntarioUrl: 'https://eeze.co/how-to-sell-your-car-in-ontario-with-eeze/',
  buyCarQuebecUrl: 'https://eeze.co/how-to-sell-your-car-in-ontario-with-eeze/',
  warrantyPlansUrl: 'https://eeze.co/get-a-warranty/',

  safetyPromoInfoUrl: 'https://eeze.co/safety-promotion/',
  eezeTransactionInfoUrl: 'https://eeze.co/how-it-works/',
  warrantyInfoUrl: 'https://eeze.co/extended-warranty-information/',
  getWarrantyUrl: 'https://eeze.co/get-a-warranty/',

  sampleCarfaxUrl: 'https://vhr.carfax.ca/en-ca/sample/vhrlc',

  walletFundCredit: 'https://buy.stripe.com/test_dR6eWM7eQ9Rrds4dQQ', // no taxes collected

  carfaxVhrCreditON: 'https://buy.stripe.com/test_6oEaGw8iU0gRgEgfYZ', // stripe collects tax
  carfaxVhrCreditQC: 'https://buy.stripe.com/test_6oEaGw8iU0gRgEgfYZ', // stripe collects tax

  warrantyPowertrain1yrCreditON: 'https://buy.stripe.com/test_7sI01Sar24x787K6oH', // stripe collects tax
  warrantyPowertrain1yrCreditQC: 'https://buy.stripe.com/test_7sI01Sar24x787K6oH', // stripe collects tax

  warrantyComprehensive1yrCreditON: 'https://buy.stripe.com/test_6oE5mc9mY1kVds4bJ2',
  warrantyComprehensive1yrCreditQC: 'https://buy.stripe.com/test_6oE5mc9mY1kVds4bJ2', // same, stripe calculates tax

  warrantyComprehensive2yrCreditON: 'https://buy.stripe.com/test_7sI3e47eQ3t32NqaEZ',
  warrantyComprehensive2yrCreditQC: 'https://buy.stripe.com/test_7sI3e47eQ3t32NqaEZ',

  warrantyComprehensive3yrCreditON: 'https://buy.stripe.com/test_6oEdSI7eQgfP4Vy9AW',
  warrantyComprehensive3yrCreditQC: 'https://buy.stripe.com/test_6oEdSI7eQgfP4Vy9AW',

  // not used anymore
  warrantyPowertrain1yrCreditUpgradeON: 'https://buy.stripe.com/test_8wMbKAdDe9Rr9bO146', // stripe collects tax
  warrantyPowertrain1yrCreditUpgradeQC: 'https://buy.stripe.com/test_8wMbKAdDe9Rr9bO146', // stripe collects tax
  warrantyComprehensive1yrCreditUpgradeON: 'https://buy.stripe.com/test_6oE2a042E0gRew8dR0',
  warrantyComprehensive1yrCreditUpgradeQC: 'https://buy.stripe.com/test_3cs6qg7eQ7Jj73G5kv',
  warrantyComprehensive2yrCreditUpgradeON: 'https://buy.stripe.com/test_14k9Cs8iU2oZ4VydR2',
  warrantyComprehensive2yrCreditUpgradeQC: 'https://buy.stripe.com/test_3csaGweHiaVvfAceV7',
  warrantyComprehensive3yrCreditUpgradeON: 'https://buy.stripe.com/test_cN26qg56I4x79bObIW',
  warrantyComprehensive3yrCreditUpgradeQC: 'https://buy.stripe.com/test_8wMg0Q6aMaVvds47sH',
} : {
  privacyUrl: 'https://eeze.co/privacy-policy/',
  termsUrl: 'https://eeze.co/terms-of-use/',
  amlUrl: 'https://eeze.co/aml-program/',
  googleReview: 'https://g.page/r/CY3GFDptbIQMEBI/review',
  contactUrl: 'https://eeze.co/contact-us/',
  buyCarOntarioUrl: 'https://eeze.co/how-to-sell-your-car-in-ontario-with-eeze/',
  buyCarQuebecUrl: 'https://eeze.co/how-to-sell-your-car-in-ontario-with-eeze/',
  warrantyPlansUrl: 'https://eeze.co/get-a-warranty/',

  safetyPromoInfoUrl: 'https://eeze.co/safety-promotion/',
  eezeTransactionInfoUrl: 'https://eeze.co/how-it-works/',
  warrantyInfoUrl: 'https://eeze.co/extended-warranty-information/',
  getWarrantyUrl: 'https://eeze.co/get-a-warranty/',

  sampleCarfaxUrl: 'https://vhr.carfax.ca/en-ca/sample/vhrlc',

  walletFundCredit: 'https://buy.stripe.com/cN2eYK2Qu3oU4rSbIX',

  carfaxVhrCreditON: 'https://buy.stripe.com/8wMg2Oezc8Je9Mc9AO',
  carfaxVhrCreditQC: 'https://buy.stripe.com/8wMg2Oezc8Je9Mc9AO',
  warrantyPowertrain1yrCreditON: 'https://buy.stripe.com/dR603Q3Uy1gM4rSaEU',
  warrantyPowertrain1yrCreditQC: 'https://buy.stripe.com/dR603Q3Uy1gM4rSaEU',
  warrantyComprehensive1yrCreditON: 'https://buy.stripe.com/eVacQCgHkbVq3nO7sJ',
  warrantyComprehensive1yrCreditQC: 'https://buy.stripe.com/eVacQCgHkbVq3nO7sJ',
  warrantyComprehensive2yrCreditON: 'https://buy.stripe.com/3cs4k68aO6B66A028q',
  warrantyComprehensive2yrCreditQC: 'https://buy.stripe.com/3cs4k68aO6B66A028q',
  warrantyComprehensive3yrCreditON: 'https://buy.stripe.com/6oE9Eq76K1gM0bC9AT',
  warrantyComprehensive3yrCreditQC: 'https://buy.stripe.com/6oE9Eq76K1gM0bC9AT',
  // warrantyPowertrain1yrCreditON: 'https://buy.stripe.com/6oE9Eq4YC9NiaQgfZa',
  // warrantyPowertrain1yrCreditQC: 'https://buy.stripe.com/6oE9Eq4YC9NiaQgfZa',
  // warrantyComprehensive1yrCreditON: 'https://buy.stripe.com/dR69Eqcr4aRm7E4fZ9',
  // warrantyComprehensive1yrCreditQC: 'https://buy.stripe.com/5kAaIuezc7FabUk14e',
  // warrantyComprehensive2yrCreditON: 'https://buy.stripe.com/5kAcQCdv8e3ycYoaEN',
  // warrantyComprehensive2yrCreditQC: 'https://buy.stripe.com/aEU03Q8aO7Faf6w7sA',
  // warrantyComprehensive3yrCreditON: 'https://buy.stripe.com/7sI9Eq1MqaRm0bC7sz',
  // warrantyComprehensive3yrCreditQC: 'https://buy.stripe.com/6oEdUG62G5x2f6wcMS',

  // not used anymore
  warrantyPowertrain1yrCreditUpgradeON: 'https://buy.stripe.com/aEU2bY4YC0cI9Mc5kx',
  warrantyPowertrain1yrCreditUpgradeQC: 'https://buy.stripe.com/aEU2bY4YC0cI9Mc5kx',
  warrantyComprehensive1yrCreditUpgradeON: 'https://buy.stripe.com/bIY3g20ImaRmgaA005',
  warrantyComprehensive1yrCreditUpgradeQC: 'https://buy.stripe.com/6oE6se76Ke3y1fG004',
  warrantyComprehensive2yrCreditUpgradeON: 'https://buy.stripe.com/cN2eYKaiWaRmgaA7sv',
  warrantyComprehensive2yrCreditUpgradeQC: 'https://buy.stripe.com/4gwbMybn0aRmf6w4gi',
  warrantyComprehensive3yrCreditUpgradeON: 'https://buy.stripe.com/5kA5oacr43oUf6waEF',
  warrantyComprehensive3yrCreditUpgradeQC: 'https://buy.stripe.com/dR6aIucr43oUaQg288',
};

// pricing before tax
export const PRICING_OPTIONS = {
  transaction: {
    nsf: 20,
    fundMinimum: 2,
    fundMaximum: 25000,
  },
  addons: {
    "carfax": {
      name: "Vehicle History Report + Lien Check",
      "nameFr": "Rapport d'historique du véhicule + vérification de charges financières",
      description: "",
      descriptionFr: "",
      code: "carfax",
      type: "CARFAX",
      on: {
        checkoutUrl: LINK.carfaxVhrCreditON,
        priceIncludingTax: 73.45,
        priceBeforeTax: 65,
        taxes: {
          gst: 8.45,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.carfaxVhrCreditQC,
        priceIncludingTax: 74.73,
        priceBeforeTax: 65,
        taxes: {
          gst: 3.25,
          qst: 6.48,
        },
      },
    },
    "BASE90": {
      name: "Powertrain 90-Days",
      warrantyName: "Powertrain 90-Days",
      term: "90 Days",
      description: "Included in EEZE Plan",
      "nameFr": "Groupe motopropulseur 90 jours",
      "warrantyNameFr": "Groupe motopropulseur 90 jours",
      "termFr": "90 jours",
      "descriptionFr": "Inclus dans le plan EEZE",
      code: "BASE90",
      type: "BASE",
      on: {
        checkoutUrl: '',
        priceIncludingTax: 0,
        priceBeforeTax: 0,
        taxes: {
          gst: 0,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: '',
        priceIncludingTax: 0,
        priceBeforeTax: 0,
        taxes: {
          gst: 0,
          qst: 0,
        },
      },
    },
    "A1": {
      name: "Powertrain",
      warrantyName: "Powertrain 1-Year",
      term: "1 Year",
      feature1: "$0 Deductible",
      feature2: "$1,500 Maximum per claim",
      feature3: "Max claims - vehicle value",
      price1: "$575",
      price2: "~ $50/m x 12 ",
      "nameFr": "Groupe motopropulseur",
      "warrantyNameFr": "Groupe motopropulseur 1 an",
      "termFr": "1 an",
      "feature1Fr": "0 $ de franchise",
      "feature2Fr": "1 500 $ max par réclamation",
      "feature3Fr": "Réclamations max - valeur du véhicule",
      "price1Fr": "575 $ + taxes",
      "price2Fr": "~ 50$/m x 12 ",
      code: "A1",
      type: "WARRANTY",
      on: {
        checkoutUrl: LINK.warrantyPowertrain1yrCreditON,
        priceIncludingTax: 649.75,
        priceBeforeTax: 575,
        taxes: {
          gst: 74.75,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.warrantyPowertrain1yrCreditQC,
        priceIncludingTax: 661.11,
        priceBeforeTax: 575,
        taxes: {
          gst: 28.75,
          qst: 57.36,
        },
      },
    },
    "B1": {
      name: "Comprehensive",
      warrantyName: "Comprehensive 1-Year",
      feature1: "$0 Deductible",
      feature2: "$5,000 Maximum per claim",
      feature3: "Max claims - vehicle value",
      term: "1 Year",
      price1: "$1,275",
      price2: "~ $76/m x 18 ",
      "nameFr": "Complet",
      "warrantyNameFr": "Complet 1 an",
      "feature1Fr": "0 $ de franchise",
      "feature2Fr": "5 000 $ max par réclamation",
      "feature3Fr": "Réclamations max - valeur du véhicule",
      "termFr": "1 an",
      "price1Fr": "1 275 $ + taxes",
      "price2Fr": "~ 76$/m x 18 ",
      code: "B1",
      type: "WARRANTY",
      on: {
        checkoutUrl: LINK.warrantyComprehensive1yrCreditON,
        priceIncludingTax: 1440.75,
        priceBeforeTax: 1275,
        taxes: {
          gst: 165.75,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.warrantyComprehensive1yrCreditQC,
        priceIncludingTax: 1465.93,
        priceBeforeTax: 1275,
        taxes: {
          gst: 63.75,
          qst: 127.18,
        },
      },
    },
    "B2": {
      name: "Comprehensive",
      warrantyName: "Comprehensive 2-Year",
      feature1: "$0 Deductible",
      feature2: "$5,000 Maximum per claim",
      feature3: "Max claims - vehicle value",
      term: "2 Year",
      price1: "$1,875",
      price2: "~ $86/m x 24 ",
      "nameFr": "Complet",
      "warrantyNameFr": "Complet 2 ans",
      "feature1Fr": "0 $ de franchise",
      "feature2Fr": "5 000 $ max par réclamation",
      "feature3Fr": "Réclamations max - valeur du véhicule",
      "termFr": "2 ans",
      "price1Fr": "1 875 $ + taxes",
      "price2Fr": "~ 86$/m x 24 ",
      code: "B2",
      type: "WARRANTY",
      on: {
        checkoutUrl: LINK.warrantyComprehensive2yrCreditON,
        priceIncludingTax: 2118.75,
        priceBeforeTax: 1875,
        taxes: {
          gst: 243.75,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.warrantyComprehensive2yrCreditQC,
        priceIncludingTax: 2155.78,
        priceBeforeTax: 1875,
        taxes: {
          gst: 93.75,
          qst: 187.03,
        },
      },
    },
    "B3": {
      name: "Comprehensive",
      warrantyName: "Comprehensive 3-Year",
      feature1: "$0 Deductible",
      feature2: "$5,000 Maximum per claim",
      feature3: "Max claims - vehicle value",
      term: "3 Year",
      price1: "$2,175",
      price2: "~ $100/m x 24 ",
      "nameFr": "Complet",
      "warrantyNameFr": "Complet 3 ans",
      "feature1Fr": "0 $ de franchise",
      "feature2Fr": "5 000 $ max par réclamation",
      "feature3Fr": "Réclamations max - valeur du véhicule",
      "termFr": "3 ans",
      "price1Fr": "2 175 $ + taxes",
      "price2Fr": "~ 100$/m x 24 ",
      code: "B3",
      type: "WARRANTY",
      on: {
        checkoutUrl: LINK.warrantyComprehensive3yrCreditON,
        priceIncludingTax: 2457.75,
        priceBeforeTax: 2175,
        taxes: {
          gst: 282.75,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.warrantyComprehensive3yrCreditQC,
        priceIncludingTax: 2500.71,
        priceBeforeTax: 2175,
        taxes: {
          gst: 108.75,
          qst: 216.96,
        },
      },
    },
    "A1U": {
      name: "Powertrain",
      warrantyName: "Powertrain 1-Year Upgrade",
      feature1: "$0 Deductible",
      feature2: "$1,500 Maximum per claim",
      feature3: "Max claims - vehicle value",
      term: "1 Year",
      price1: "$475 + tax",
      price2: "~ $48/m x 12 ",
      "nameFr": "Groupe motopropulseur",
      "warrantyNameFr": "Groupe motopropulseur 1 an amélioration",
      "feature1Fr": "0 $ de franchise",
      "feature2Fr": "1 500 $ max par réclamation",
      "feature3Fr": "Réclamations max - valeur du véhicule",
      "termFr": "1 an",
      "price1Fr": "475 $ + taxes",
      "price2Fr": "~ 48$/m x 12 ",
      code: "A1U",
      type: "WARRANTY",
      upgrade: true,
      on: {
        checkoutUrl: LINK.warrantyPowertrain1yrCreditUpgradeON,
        priceIncludingTax: 536.75,
        priceBeforeTax: 475,
        taxes: {
          gst: 61.75,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.warrantyPowertrain1yrCreditUpgradeQC,
        priceIncludingTax: 546.13,
        priceBeforeTax: 475,
        taxes: {
          gst: 23.75,
          qst: 47.38,
        },
      },
    },
    "B1U": {
      name: "Comprehensive",
      warrantyName: "Comprehensive 1-Year Upgrade",
      feature1: "$0 Deductible",
      feature2: "$5,000 Maximum per claim",
      feature3: "Max claims - vehicle value",
      term: "1 Year",
      price1: "$980 + tax",
      price2: "~ $67/m x 18 ",
      "nameFr": "Complet",
      "warrantyNameFr": "Complet 1 an amélioration",
      "feature1Fr": "0 $ de franchise",
      "feature2Fr": "5 000 $ max par réclamation",
      "feature3Fr": "Réclamations max - valeur du véhicule",
      "termFr": "1 an",
      "price1Fr": "980 $ + taxes",
      "price2Fr": "~ 67$/m x 18 ",
      code: "B1U",
      type: "WARRANTY",
      upgrade: true,
      on: {
        checkoutUrl: LINK.warrantyComprehensive1yrCreditUpgradeON,
        priceIncludingTax: 1107.4,
        priceBeforeTax: 980,
        taxes: {
          gst: 127.4,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.warrantyComprehensive1yrCreditUpgradeQC,
        priceIncludingTax: 1126.76,
        priceBeforeTax: 980,
        taxes: {
          gst: 49,
          qst: 97.76,
        },
      },
    },
    "B2U": {
      name: "Comprehensive",
      warrantyName: "Comprehensive 2-Year Upgrade",
      feature1: "$0 Deductible",
      feature2: "$5,000 Maximum per claim",
      feature3: "Max claims - vehicle value",
      term: "2 Year",
      price1: "$1,680 + tax",
      price2: "(~ $88/m x 24)",
      "nameFr": "Complet",
      "warrantyNameFr": "Complet 2 ans amélioration",
      "feature1Fr": "0 $ de franchise",
      "feature2Fr": "5 000 $ max par réclamation",
      "feature3Fr": "Réclamations max - valeur du véhicule",
      "termFr": "2 ans",
      "price1Fr": "1 680 $ + taxes",
      "price2Fr": "~ 88$/m x 24 ",
      code: "B2U",
      type: "WARRANTY",
      upgrade: true,
      on: {
        checkoutUrl: LINK.warrantyComprehensive2yrCreditUpgradeON,
        priceIncludingTax: 1898.4,
        priceBeforeTax: 1680,
        taxes: {
          gst: 218.4,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.warrantyComprehensive2yrCreditUpgradeQC,
        priceIncludingTax: 1931.58,
        priceBeforeTax: 1680,
        taxes: {
          gst: 84,
          qst: 167.48,
        },
      },
    },
    "B3U": {
      name: "Comprehensive",
      warrantyName: "Comprehensive 3-Year Upgrade",
      feature1: "$0 Deductible",
      feature2: "$5,000 Maximum per claim",
      feature3: "Max claims - vehicle value",
      term: "3 Year",
      price1: "$1,980 + tax",
      price2: "~ $104/m x 24 ",
      "nameFr": "Complet",
      "warrantyNameFr": "Complet 3 ans amélioration",
      "feature1Fr": "0 $ de franchise",
      "feature2Fr": "5 000 $ max par réclamation",
      "feature3Fr": "Réclamations max - valeur du véhicule",
      "termFr": "3 ans",
      "price1Fr": "1 980 $ + taxes",
      "price2Fr": "~ 104$/m x 24 ",
      code: "B3U",
      type: "WARRANTY",
      upgrade: true,
      on: {
        checkoutUrl: LINK.warrantyComprehensive3yrCreditUpgradeON,
        priceIncludingTax: 2237.4,
        priceBeforeTax: 1980,
        taxes: {
          gst: 257.4,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.warrantyComprehensive3yrCreditUpgradeQC,
        priceIncludingTax: 2276.51,
        priceBeforeTax: 1980,
        taxes: {
          gst: 99,
          qst: 197.51,
        },
      },
    },
  },
};
