import {
  Center,
  Text,
  VStack,
  Image,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import repairCarChart from '../assets/Repairs-chart.jpg';
import CreditLogos from './CreditLogos';
import WarrantyCard from './WarrantyCard';

import {
  // PRICING_OPTIONS,
  LINK,
} from '../constants';

function WarrantyPromoSection({ minimal }) {
  const { t } = useTranslation('global');
  // let french = i18n.language === 'fr' ? true : false;

  // in homepage modal and warranty landing page
  return (
    <VStack>
      <br />
      <VStack spacing="0">
        <Center>
          <Text fontSize="xs" color="primary.300" fontWeight="extrabold">
            PROTECT YOUR RIDE
          </Text>
        </Center>
        <Center>
          <Text fontWeight="bold" fontSize="xl" align="center">
            Get covered up to $5,000 per claim with $0 Deductible
          </Text>
        </Center>
        <Center>
          <Text textAlign="center">
            In Canada, the average cost of vehicle repairs is $1,500 annually.
            As your vehicle ages, repair costs and risks rise. EEZE Warranty
            plans can help!
          </Text>
        </Center>
      </VStack>

      <Center>
        <Image
          src={repairCarChart}
          alt="Logo"
          width="100%"
          // mx={-5}
          // maxW="600"
          objectFit="cover"
        />
      </Center>
      <VStack spacing="0">
        <Center>
          <Text fontSize="xs" color="primary.300" fontWeight="extrabold">
            COMPARE OUR PLANS
          </Text>
        </Center>

        <Center>
          <Text fontSize="xl" fontWeight="bold">
            EEZE Warranty Plans
          </Text>
        </Center>
        <Center>
          <Text textAlign="center" fontSize="xl" fontWeight="bold">
            $0 Deductible and Financing Available
          </Text>
        </Center>
      </VStack>

     <WarrantyCard/>

      <br />

      <Text textAlign="center" fontSize={'lg'} fontWeight={'extrabold'}>
        <a
          href={LINK.warrantyInfoUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('moreInfo')}
          <ExternalLinkIcon ml="5px" />
        </a>
      </Text>
      <br />
      <Text>{t('payInUpTo24MonthlyInstallments')}</Text>

      <CreditLogos />
    </VStack>
  );
}

export default WarrantyPromoSection;
