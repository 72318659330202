import {
  VStack,
  HStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  AccordionButton,
  Box,
  Divider,
  Icon,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FaCheckCircle } from 'react-icons/fa';

function CardComprehensive() {
  const { t } = useTranslation('global');
  return (
    <VStack
      align="flex-start"
      spacing={0}
      borderWidth="1px"
      borderColor="blackAlpha.500"
      p={4}
      borderRadius={'lg'}
    >
      <Text mb={4} fontSize="lg" fontWeight="extrabold">
        Comprehensive
      </Text>
      <Text>$0 Deductible</Text>
      <Text fontWeight="bold">$5,000 Maximum per claim</Text>
      <Text>Maximum of all claims up to vehicle value</Text>
      <Text fontWeight={'bold'}>Inspection Included with EEZE</Text>
      <Text>Pay in up to 24 equal payments</Text>
      <Text fontWeight="bold">Includes 24/7 Roadside Assistance</Text>

      <Accordion allowToggle w="full" mt={2}>
        <AccordionItem>
          <AccordionButton px={0} w="100%">
            <Box as="span" flex="1" textAlign="left">
              <Text color="primary.500" fontSize={'sm'} fontWeight="bold">
                {/* Click here for coverage details */}
                What's covered?
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4} mx={0} px={0} fontSize="xs">
            <HStack>
              <Icon as={FaCheckCircle} w={'14px'} h={'14px'} color="purple" />
              <Text>{t('sharedCoverageFeature4')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'} h={'14px'} color="purple" />
              <Text>{t('sharedCoverageFeature5')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'} h={'14px'} color="purple" />
              <Text>{t('sharedCoverageFeature6')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'} h={'14px'} color="purple" />
              <Text>{t('sharedCoverageFeature7')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'} h={'14px'} color="purple" />
              <Text>{t('sharedCoverageFeature8')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'} h={'14px'} color="purple" />
              <Text>{t('sharedCoverageFeature9')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'} h={'14px'} color="purple" />
              <Text>{t('comprehensiveOnlyFeature2')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'} h={'14px'} color="purple" />
              <Text>{t('comprehensiveOnlyFeature3')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'} h={'14px'} color="purple" />
              <Text>{t('comprehensiveOnlyFeature5')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'} h={'14px'} color="purple" />
              <Text>{t('comprehensiveOnlyFeature6')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'} h={'14px'} color="purple" />
              <Text>{t('comprehensiveOnlyFeature7')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'} h={'14px'} color="purple" />
              <Text>{t('comprehensiveOnlyFeature8')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'} h={'14px'} color="purple" />
              <Text>{t('comprehensiveOnlyFeature9')}</Text>
            </HStack>

            <HStack>
              <Icon as={FaCheckCircle} w={'14px'} h={'14px'} color="purple" />
              <Text>{t('comprehensiveOnlyFeature10')}</Text>
            </HStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Text my={2} fontWeight="extrabold" fontSize="lg">
        $1,275 - 1 year coverage
      </Text>
      <Divider borderColor={'blackAlpha.400'} />
      <Text my={2} fontWeight="extrabold" fontSize="lg">
        $1,875 - 2 year coverage
      </Text>
      <Divider borderColor={'blackAlpha.400'} />

      <HStack>
        <Text my={2} fontWeight="extrabold" fontSize="lg">
          $2,175
        </Text>
        <Text my={2} fontSize="lg" fontWeight={'extrabold'}>
          - 3 year coverage
        </Text>
      </HStack>
    </VStack>
  );
}

export default CardComprehensive;
